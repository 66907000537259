import React from 'react';
import Bluedots from '../../assets/images/blue_dotes.png'
import howstep1 from '../../assets/images/snapmemories/plan.png'
import howstep2 from '../../assets/images/snapmemories/qrcode.png'
import howstep3 from '../../assets/images/snapmemories/theme.png'
import howstep4 from '../../assets/images/snapmemories/contest.jpg'
import howstep5 from '../../assets/images/snapmemories/checkout.png'

function HowWork() {
    return (

        <section className="how_it_section white_text">
            <div className="how_it_inner" data-aos="fade-in" data-aos-duration={1500}>
                <div className="dotes_blue">
                    <img src={Bluedots} alt="image"/>
                </div>
                <div className="container">
                    <div className="section_title">
                        <span className="title_badge">Acquista il tuo evento</span>
                        <h2>Come acquistare in 5 semplici steps</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-2">
                            <div className="steps_block step_border">
                                <div className="steps">
                                    <div className="icon">
                                        <img src={howstep1} alt="image"
                                             style={{
                                                 width: '8vh'
                                             }}/>
                                    </div>
                                    <div className="text">
                                        <h3>Scegli il pacchetto</h3>
                                        <p>
                                            Seleziona il pacchetto ideale per te
                                        </p>
                                    </div>
                                </div>
                                <span className="step">01</span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="steps_block step_border">
                                <div className="steps">
                                    <div className="icon">
                                        <img src={howstep2} alt="image"
                                             style={{
                                                 width: '8vh'
                                             }}/>

                                    </div>
                                    <div className="text">
                                        <h3>Crea il tuo evento</h3>
                                        <p>
                                            Scegli il tema e la data dell' evento
                                        </p>
                                    </div>
                                </div>
                                <span className="step">02</span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="steps_block step_border">
                                <div className="steps">
                                    <div className="icon">
                                        <img src={howstep3} alt="image"
                                             style={{
                                                 width: '8vh'
                                             }}/>

                                    </div>
                                    <div className="text">
                                        <h3>Personalizza la tua locandina</h3>
                                        <p>
                                            Crea e personalizza il tema della locandina
                                        </p>
                                    </div>
                                </div>
                                <span className="step">03</span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="steps_block step_border">
                                <div className="steps">
                                    <div className="icon">
                                        <img src={howstep4} alt="image"
                                             style={{
                                                 width: '8vh'
                                             }}/>
                                    </div>
                                    <div className="text">
                                        <h3>Crea un contest</h3>
                                        <p>
                                            Scegli un immagine di copertina ed un video challenge
                                        </p>
                                    </div>
                                </div>
                                <span className="step">04</span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <img src={howstep5} alt="image"
                                             style={{
                                                 width: '8vh'
                                             }}/>
                                    </div>
                                    <div className="text">
                                        <h3>Completa il pagamento</h3>
                                        <p>
                                            Riceverai per mail il tuo QRCode
                                        </p>
                                    </div>
                                </div>
                                <span className="step">05</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowWork;
