import React, {useEffect, useRef, useState} from 'react'
import Header from '../Header/Main'
import About from '../Menabout/About'
import Team from '../Menabout/Team'
import Faq from '../Menabout/Faq'
import Footer from '../Footer/Main'
import Aos from 'aos'
import TryCode from "../Menhome/TryCode";


function Main() {


    const [ytshow, setytShow] = useState(false);
    const [ytban, setytban] = useState(false);
    const videoContainerRef = useRef(null);

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

    const handlePlayClick = () => {
        setytban(true);
        setTimeout(() => {
            const videoContainer = videoContainerRef.current;
            if (videoContainer.requestFullscreen) {
                videoContainer.requestFullscreen();
            } else if (videoContainer.mozRequestFullScreen) { // Firefox
                videoContainer.mozRequestFullScreen();
            } else if (videoContainer.webkitRequestFullscreen) { // Chrome, Safari and Opera
                videoContainer.webkitRequestFullscreen();
            } else if (videoContainer.msRequestFullscreen) { // IE/Edge
                videoContainer.msRequestFullscreen();
            }

            const videoElement = document.getElementById('youtubevideo');
            videoElement.src = "https://snapmemories-produzione.s3.eu-south-1.amazonaws.com/foto+e+video/videolancio.mp4";
            videoElement.style.display = 'block'; // Mostra il video
        }, 300); // Aspetta un po' prima di mettere in fullscreen per assicurarsi che il modale sia visibile
    };

    return (
        <>
            <Header/>
            <div className="page_wrapper">
                <About/>
                <Team/>
                {/*<Ourvalue/>*/}
                {/*<div className='container'>*/}
                {/*    <div className="row justify-content-center align-items-center">*/}
                {/*        <div className="col-lg-4 col-md-6">*/}
                {/*            <div className="abtImg text-center">*/}
                {/*                <img src={appscreen} alt="image" style={{width: '100%'}}/>*/}
                {/*                <div className="btn_block" style={{*/}
                {/*                    position: 'absolute',*/}
                {/*                    top: '50%',*/}
                {/*                    left: '50%',*/}
                {/*                    transform: 'translate(-50%, -50%)'*/}
                {/*                }}>*/}
                {/*                    <Link to="/contact" className="btn puprple_btn ml-0">*/}
                {/*                        Demo gratuita*/}
                {/*                    </Link>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-lg-4 col-md-6 mt-4 mt-md-0">*/}
                {/*            <ul style={{display: "flex", justifyContent: "space-evenly"}}>*/}

                {/*                <li>*/}
                {/*                    <img src={Banner5} alt="image"/>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <img src={Banner6} alt="image"/>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <img src={Banner7} alt="image"/>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <Link to="#"*/}
                {/*                          className="popup-youtube play-button"*/}
                {/*                          data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"*/}
                {/*                          data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU"*/}
                {/*                          onClick={handlePlayClick}>*/}
                {/*                        <img src={Banner4} alt="img"/>*/}
                {/*                    </Link>*/}
                {/*                </li>*/}
                {/*            </ul>*/}


                {/*        </div>*/}
                {/*    </div>*/}
                {/*    {ytban &&*/}
                {/*        <div className="modal fade youtube-video show" id="myModal"*/}
                {/*             tabIndex={-1} style={{display: "block", paddingRight: 17}}*/}
                {/*             aria-modal="true" role="dialog" ref={videoContainerRef}>*/}
                {/*            <div className="modal-dialog" role="document">*/}
                {/*                <div className="modal-content" onClick={() => setytban(false)}>*/}
                {/*                    <button id="close-video"*/}
                {/*                            type="button" className="button btn btn-default text-right"*/}
                {/*                            data-dismiss="modal"*/}
                {/*                            onClick={() => setytban(false)}>*/}
                {/*                        <i className="icofont-close-line-circled"/>*/}
                {/*                    </button>*/}
                {/*                    <div className="modal-body">*/}
                {/*                        <div id="video-container" className="video-container">*/}
                {/*                            <iframe id="youtubevideo"*/}
                {/*                                    width={640} height={360} allowFullScreen={true}*/}
                {/*                                    src="" // L'URL verrà impostato dinamicamente*/}
                {/*                                    style={{display: 'none'}} // Nascondi l'iframe fino a quando non viene cliccato play*/}
                {/*                            />*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="modal-footer"></div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    }*/}
                {/*</div>*/}
                <TryCode/>
                <Faq/>
                {/* <App /> */}
                <Footer/>
            </div>
        </>
    )
}

export default Main