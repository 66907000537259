import * as React from "react"
const StandardSvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={80}
        height={80}
        viewBox="0 0 1024 1024"
        {...props}
    >
        <path d="M38 170.5V303h265V38H38v132.5zm227 .5v94H77V77h188v94z" />
        <path d="M114 170.5V227h113V114H114v56.5zM347 95v57h27V38h-27v57zM385 76v38h25.9l.3-19.8c.2-10.8.2-27.9 0-38L411 38h-26v38zM536.8 94.7c-.2 31.3-.2 65.5 0 76l.2 19.3h26V38h-26l-.2 56.7zM721 170.5V303h265V38H721v132.5zm226 .5v94H759V77h188v94z" />
        <path d="M797 170.5V227h113V114H797v56.5zM499 57v13h26V44h-26v13zM575 57v13h26V44h-26v13zM650 170.5V265h27V76h-27v94.5zM423 95v13h26V82h-26v13zM461 95v13h26V82h-26v13zM612.8 132.2c-.2 10.1-.2 27.2 0 38l.3 19.8H639v-76h-26l-.2 18.2zM575 132.5V146h26v-27h-26v13.5zM385 208.5V265h26V152h-26v56.5zM423 190v38h25.9l.3-19.8c.2-10.8.2-27.9 0-38L449 152h-26v38zM461 208.5V222h26v-27h-26v13.5zM537 246.5V260h26v-27h-26v13.5zM423 360v57h26V303h-26v57zM499 341v38h26v-76h-26v38zM613 322v171h26V303h-26v19zM385 322.5V336h26v-27h-26v13.5zM537 322.5V336h26v-27h-26v13.5zM157 398v57h27V341h-27v57zM802 379v38h27v-76h-27v38zM878 379v38h27v-76h-27v38zM916 379v38h26l.2-18.3c.2-10 .2-27.1 0-38l-.3-19.7H916v38zM44 360.5V374h26v-27H44v13.5zM688 360.5V374h27v-27h-27v13.5zM954 360.5V374h26v-27h-26v13.5zM119 455v76h27V379h-27v76zM195 436v57h27V379h-27v57zM537 417v38h26v-76h-26v38zM840 436v57h27V379h-27v57zM575 398v13h26v-26h-26v13zM764 398v13h27v-26h-27v13zM44 511.5V683h26V417H44v94.5zM82 436v171h26V417H82v19zM726 474v57h27V417h-27v57zM954 474v57h26V417h-26v57zM233 436v13h27v-26h-27v13zM309 436v13h27v-26h-27v13zM347 436v13h27v-26h-27v13zM650 436v13h27v-26h-27v13zM688 436v13h27v-26h-27v13zM385 512v57h26V455h-26v57zM461 493v38h26v-76h-26v38zM764 493v38h27v-76h-27v38zM499 474v13h26v-26h-26v13zM802 474v13h27v-26h-27v13zM878 474v13h27v-26h-27v13zM916 474v13h26v-26h-26v13zM650 531v38h27v-76h-27v38zM233 512v13h27v-26h-27v13zM423 512v13h26v-26h-26v13zM195 607v76h27V531h-27v76zM309 588v57h27V531h-27v57zM612.8 549.2c-.2 10.1-.2 27.2 0 38l.3 19.8H639v-76h-26l-.2 18.2zM802 588v57h-49v-76h-27v76h-81v189h5v38h27v-38h11v76h27v-76h11v38h27v-38h11v114h27V834h43V645h-5V531h-27v57zm5 151.5V807H672V672h135v67.5z" />
        <path d="M721 740v19h38v-38h-38v19zM916 569v38h25.9l.3-19.8c.2-10.8.2-27.9 0-38L942 531h-26v38zM499 550v13h26v-26h-26v13zM575 550v13h26v-26h-26v13zM688 550v13h27v-26h-27v13zM233 626v57h27V569h-27v57zM347 607v38h27v-76h-27v38zM878 626v57h27V569h-27v57zM954 607v38h25.9l.3-19.8c.2-10.8.2-27.9 0-38L980 569h-26v38zM537 588v13h26v-26h-26v13zM840 588v13h27v-26h-27v13zM499 645v38h26v-76h-26v38zM157 626v13h27v-26h-27v13zM461 683v38h25.9l.3-19.8c.2-10.8.2-27.9 0-38L487 645h-26v38zM916 683v38h25.9l.3-19.8c.2-10.8.2-27.9 0-38L942 645h-26v38zM423 663.5V677h26v-27h-26v13.5zM537 663.5V677h26v-27h-26v13.5zM347 777.5V872h27V683h-27v94.5zM385 739.5V796h26V683h-26v56.5zM575 701.5V715h26v-27h-26v13.5zM38 853.5V986h265V721H38v132.5zm227-.5v94H77V759h188v94z" />
        <path d="M114 853.5V910h113V797H114v56.5zM537 739.5V986h26V721h-26v18.5zM499 739.5V753h26v-27h-26v13.5zM840 739.5V753h27v-27h-27v13.5zM954 739.5V753h26v-27h-26v13.5zM461 796.5V834h25.9l.3-19.3c.2-10.5.2-27.4 0-37.5L487 759h-26v37.5zM613 777.5V872h26V759h-26v18.5zM575 777.5V791h26v-27h-26v13.5zM840 834v38h27v-76h-27v38zM916 872v76h26V796h-26v76zM954 891v95h26V796h-26v95zM385 872v38h26l.2-18.3c.2-10 .2-27.1 0-38l-.3-19.7H385v38zM423 853.5V867h26v-27h-26v13.5zM499 853.5V867h26v-27h-26v13.5zM575 853.5V867h26v-27h-26v13.5zM461 929v57h26V872h-26v57zM802 910v38h27v-76h-27v38zM878 910v38h27v-76h-27v38zM499 948v38h26v-76h-26v38zM423 929v13h26v-26h-26v13zM347 967v13h27v-26h-27v13zM385 967v13h26v-26h-26v13zM650 967v13h27v-26h-27v13zM726 967v13h27v-26h-27v13zM840 967v13h27v-26h-27v13z" />
    </svg>
)
export default StandardSvg
