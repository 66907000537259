import React from 'react';
import Blog1 from '../../assets/images/snapmemories/banner/locandina1.jpg';
import Blog2 from '../../assets/images/snapmemories/banner/locandina2.jpg';
import Blog3 from '../../assets/images/snapmemories/banner/locandina3.jpg';
import Blog4 from '../../assets/images/snapmemories/banner/locandina4.jpg';
import Blog5 from '../../assets/images/snapmemories/banner/locandina5.jpg';

function Articals() {
    const blogData = [
        {
            id: 1,
            imageSrc: Blog1,
            tag: ['Compleanno', 'Feste'],
            title: 'Party',
            description:
                'Organizzare una festa richiede attenzione ai dettagli, e una locandina accattivante può fare la differenza. <br/> ' +
                'Questa locandina è perfetta per feste di compleanno o qualsiasi tipo di festeggiamento grazie ai suoi colori vivaci.',

        },
        {
            id: 2,
            imageSrc: Blog2,
            tag: ['Compleanno', 'Matrimonio', 'Laurea', 'Anniversario'],
            title: 'Old Rose Floral',
            description: 'I colori predominanti di questa locandina sono il rosa antico e l\'arancione chiaro. <br/>' +
                'E\' ideale per matrimoni, grazie al suo design elegante e raffinato. I toni delicati e le grafiche floreali creano un\'atmosfera romantica, ideale per celebrare un giorno così speciale',
        },

        {
            id: 3,
            imageSrc: Blog3,
            tag: ['Compleanno', 'Matrimonio', 'Laurea', 'Anniversario'],
            title: 'Green Floral',
            description: 'I colori predominanti di questa locandina sono il verde e il bianco perla. <br/>' +
                'E\' ideale per matrimoni, grazie al suo design elegante e raffinato. <br/>' +
                'I toni delicati e le grafiche floreali creano un\'atmosfera romantica, ideale per celebrare un giorno così speciale',

        },

        {
            id: 4,
            imageSrc: Blog4,
            tag: ['Compleanno', 'Matrimonio', 'Laurea', 'Anniversario'],
            title: 'Old Rose Leaves',
            description: 'I colori principali di questa locandina sono il rosa e l\'arancione chiaro. <br/>' +
                'Le grafiche raffigurano foglie che aggiungono un tocco di naturalezza, creando un\'atmosfera romantica ideale per celebrare momenti significativi come anniversari, compleanni o matrimoni.'

        },
        {
            id: 5,
            imageSrc: Blog5,
            tag: ['Compleanno', 'Matrimonio', 'Laurea', 'Anniversario'],
            title: 'Gold Leaves',
            description: 'Il colore principale di questa locandina è l\'oro. <br/>' +
                'Le grafiche raffigurano foglie che aggiungono un tocco di naturalezza, creando un\'atmosfera romantica ideale per celebrare momenti significativi come anniversari, compleanni o matrimoni.'


        },
    ];

    return (
        <>
            <section className="articles_section row_am">
                <div className="container">
                    <div className="blog_listings">
                        {blogData.map((blog) => (
                            <div key={blog.id} className="listing_block" data-aos="fade-up" data-aos-duration={1500}>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                    className="img">
                                    <img style={{width: "80%"}} src={blog.imageSrc} alt="image"/>
                                </div>
                                <div className="blog_text" style={{width: "100%"}}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        flexWrap: "wrap",
                                        marginTop: "15px"
                                    }}>
                                        {blog.tag.map((tag, index) => (
                                            <div>
                                                <span className="tag">{tag}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <h2>
                                        <div style={{fontSize: "32px"}}>{blog.title}</div>
                                    </h2>
                                    <p style={{fontSize: "21px"}} dangerouslySetInnerHTML={{__html: blog.description}}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Articals;
