import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Home from '../components/Home/Main'
import About from '../components/About/Main'
import Contact from '../components/Contact/Main'
import Banner from '../components/Banner/Main'
import ProductShop from "../components/ProductShop/Main";
import SuccessPage from "../components/ProductShop/SuccessPage";
import {ProfilePage} from "../components/ProductShop/ProfilePage";
import SignIn from "../components/Login/SignIn/Main";

const Routing = () => {

    return (
        <>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/come-funziona" element={<About/>}/>
                <Route path="/acquista" element={<ProductShop/>}/>
                <Route path="/profile-page/success" element={<SuccessPage/>}/>
                <Route path="/profile-page" element={<ProfilePage/>}/>
                <Route path="/banner" element={<Banner/>}/>
                <Route path="/login" element={<SignIn/>}/>

                {/*
                    <Route path="/blog-list" element={<BlogList />} />
                    <Route path="/blog-detail" element={<BlogDetails />} />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/reviews" element={<Reviews />} />
                    <Route path="/bloglist" element={<BlogList />} />
                */}
            </Routes>
        </>
    )
}

export default Routing