import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../../../assets/images/logonero.png'
import Mailicon from "../../../assets/images/mail_icon.png";
import Phoneicon from "../../../assets/images/phone_icon.png";
import whatsappicon from "../../../assets/images/snapmemories/whatsapp.png";
import {isMobile} from "react-device-detect";

function SignIn() {
    return (
        <>
            <div className="page_wrapper">
                <div className="full_bg">
                    <section className="signup_section">
                        <div className="container">
                            <div className="top_part" style={{
                                display: "flex",
                                "flexDirection": "column"
                            }}>
                                <div style={{marginBottom: "60px"}}>
                                    <Link to="/" className="back_btn">
                                        <i className="icofont-arrow-left"/> Torna indietro
                                    </Link>
                                </div>
                                <div>
                                    <img src={logo} alt="image" style={{width: isMobile ? "35vh" : "50vh"}}/>
                                </div>
                            </div>
                            <div className="form_block" style={{display: 'block'}}>
                                <div className="form_side"
                                     style={{
                                         padding: "15px",
                                         width: "100%",
                                     }}>
                                    <div className="section_title" style={{textAlign: "center"}}>
                                        <span className="title_badge">Benvenuto</span>
                                        <h2>
                                            La pagina di Login è attualmente
                                            <span> in manutenzione</span>
                                        </h2>
                                        <p>Seguici sui nostri social per rimanere aggiornato sulle ultime novità!</p>
                                        <p>Hai bisogno di assistenza? Siamo qui per aiutarti, contattaci:</p>
                                    </div>
                                    <ul className="contact_listing">
                                        <li>
                                            <span className="icon">
                                                <img src={Mailicon} alt="image"/>
                                            </span>
                                            <span className="lable">Inviaci una mail</span>
                                            <Link to="mailto:info@maffle.it">info@maffle.it</Link>
                                        </li>
                                        <li>
                                            <span className="icon">
                                                <img src={Phoneicon} alt="image"/>
                                            </span>
                                            <span className="lable">Chiamaci</span>
                                            <Link to="tel:3343207650">+393343207650</Link>
                                        </li>
                                        <li>
                                        <span className="icon">
                                            <img src={whatsappicon} alt="image" style={{
                                                width: '7vh'
                                            }}/>
                                              </span>
                                            <span className="lable">Whatsapp</span>
                                            <Link
                                                to="https://wa.me/+393791785539?text=Sarei%20interessato%20all'%20acquisto%20di%20un%20QRcode">Avvia
                                                la chat
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default SignIn;