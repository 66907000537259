import React, {useState} from "react";
import {Link} from "react-router-dom";
import {TypeAnimation} from "react-type-animation";
import Banner1 from "../../assets/images/snapmemories/home/home-balls-3.png";
import Banner2 from "../../assets/images/snapmemories/home/home-balls-2.png";
import Banner3 from "../../assets/images/snapmemories/home/home-balls-1.png";
import Banner7 from "../../assets/images/smallStar.png";
import Banner8 from "../../assets/images/bigstar.png";
import Banner10 from "../../assets/images/snapmemories/home/home-banner-1.png";
import Screen1 from "../../assets/images/snapmemories/home/bannerScreen.png";
import Icon from "../../assets/images/snapmemories/home/iphonescren.png";
// import Icon1 from "../../assets/images/snapmemories/home/scritta_verde.png";
import Icon1 from "../../assets/images/snapmemories/home/scritta_bianca.png";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


const Slidernew = {
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 1,
        },
        1000: {
            items: 1,
        },
    },
};


function BannerSectionHome({scrollToTarget}) {
    const [ytban, setytban] = useState(false);


    return (
        <>
            <section className="banner_section_home">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration={2000}>
                            <div className="banner_text">
                                <div className="type-wrap">
                                    <TypeAnimation id="typed"
                                                   sequence={["NESSUN LOGIN...", 800, "NESSUNA APPLICAZIONE DA INSTALLARE...", 800]}
                                                   style={{whiteSpace: "pre"}} repeat={Infinity}/>
                                </div>
                                <h1 style={{fontSize: "48px"}}>
                                    Snap<span>Memories</span>
                                </h1>
                                <p style={{fontSize: "21px"}}>
                                    Snapmemories è una piattaforma web che consente agli ospiti di condividere foto e
                                    video del tuo evento in un unico spazio condiviso e personalizzato
                                </p>
                            </div>
                            <div className="used_app">
                                <ul>
                                    <li>
                                        <img src={Banner1} alt="image"/>
                                    </li>
                                    <li>
                                        <img src={Banner2} alt="image"/>
                                    </li>
                                    <li>
                                        <img src={Banner3} alt="image"/>
                                    </li>
                                    {/* <li>
                      <Link to="#"
                            className="popup-youtube play-button"
                            data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
                            data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU"
                            onClick={() => setytban(true)}>
                        <img src={Banner4} alt="img"/>
                      </Link>
                    </li>*/}
                                </ul>
                                <div className="btn_block" onClick={scrollToTarget}>
                                    <div className="btn puprple_btn ml-0" style={{fontSize: "25px"}}>
                                        Acquista
                                    </div>
                                    <div className="btn_bottom"/>
                                </div>
                                {/*<p>
                  The best application to manage your <br/> customer worldwide
                </p>*/}
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-12" style={{paddingRight: "0px", paddingLeft: "0px"}}>
                            <div className="banner_slider">
                                <div className="left_icon">
                                    <img src={Banner7} alt="image"/>
                                </div>
                                <div className="right_icon">
                                    <img src={Banner8} alt="image"/>
                                </div>
                                <Swiper id="frmae_slider" className="mySwiper" slidesPerView={1} autoplay={true}
                                        pagination={true}
                                        {...Slidernew} >
                                    <SwiperSlide className="item">
                                        <div className="slider_img">
                                            <img src={Banner10} alt="image"/>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="item">
                                        <div className="slider_img">
                                            <img src={Screen1} alt="image"/>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                                <div className="slider_frame">
                                    <img src={Icon} alt="image"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="spinBlock" data-aos="fade-up" data-aos-duration={1500}>
                      <span className="star">
                        <img src={Banner7} alt="image"/>
                      </span>
                        <div className="spin_box" id="scrollButton">
                            <div className="">
                                <img src={Icon1} alt="image" style={{
                                    width: "14vh"
                                }}/>
                            </div>
                        </div>
                        <span className="star">
              <img src={Banner7} alt="image"/>
            </span>
                    </div>
                </div>
            </section>

            {ytban && (
                <div className="modal fade youtube-video show" id="myModal" tabIndex={-1}
                     style={{display: "block", paddingRight: 17}} aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" onClick={() => setytban(false)}>
                            <button id="close-video"
                                    type="button"
                                    className="button btn btn-default text-right"
                                    data-dismiss="modal"
                                    onClick={() => setytban(false)}>
                                <i className="icofont-close-line-circled"/>
                            </button>
                            <div className="modal-body">
                                <div id="video-container" className="video-container">
                                    <iframe
                                        id="youtubevideo"
                                        width={640}
                                        height={360}
                                        allowFullScreen=""
                                        src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer"></div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default BannerSectionHome;