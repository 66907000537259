import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import Interscrn1 from '../../assets/images/snapmemories/home/home-slider-2.png'
import Interscrn2 from '../../assets/images/snapmemories/home/home-slider-1.png'
import Interscrn3 from '../../assets/images/snapmemories/home/home-slider-3.png'
import Interscrn4 from '../../assets/images/snapmemories/home/home-slider-4.png'
import Interscrn5 from '../../assets/images/snapmemories/home/home-slider-5.png'
import Interscrn6 from '../../assets/images/snapmemories/home/home-slider-6.png'
import Interscrn7 from '../../assets/images/snapmemories/home/home-slider-7.png'
import Interscrn8 from '../../assets/images/snapmemories/home/home-slider-8.png'
import Interscrn9 from '../../assets/images/snapmemories/home/home-slider-9.png'
import Interscrn10 from '../../assets/images/snapmemories/home/home-slider-10.png'

const interfaceslider = {
    loop: false,
    effect: "coverflow",
    margin: 20,
    centeredSlides: false,
    coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 4,
        slideShadows: false
    },

    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
}

function InterfaceHome() {
    return (
        <>
            <section className="row_am interface_section">
                <div className="container-fluid">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300}>
                        <h2>
                            Un'interfaccia <span>alla moda</span>
                        </h2>
                    </div>
                    <div className="screen_slider" data-aos="fade-up" data-aos-duration={1500}>
                        <Swiper
                            id="screen_slider"
                            className="" {...interfaceslider}
                            spaceBetween={100}
                            allowTouchMove={true}
                            pagination={true}
                            modules={[Pagination]}
                            slidesPerView={5}
                            autoplay={false}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                600: {
                                    slidesPerView: 2,
                                },
                                1000: {
                                    slidesPerView: 5,
                                },
                                1400: {
                                    slidesPerView: 6,
                                },
                            }}
                        >

                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn1} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn7} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn3} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn4} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn5} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn6} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn2} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn8} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn9} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn10} alt="image" />
                                </div>
                            </SwiperSlide>




                        </Swiper>
                    </div>
                </div>
            </section>

        </>
    )
}

export default InterfaceHome