function DetailsOrdine({checkout}) {
    return (
        <div className="your-order-area">
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", margin: "0 0 20px"}}>

                <div style={{
                    border: "2px solid #629665",
                    padding: "5px",
                    backgroundColor: "#629665",
                    borderRadius: "25px",
                    color: "white",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%"
                }}
                >
                    <div style={{marginRight: "6px"}}><i class="fa fa-gift"
                                                         style={{fontSize: "23px"}}></i></div>

                    <div>Riepilogo del tuo evento</div>
                </div>

            </div>
            <div className="your-order-wrap gray-bg-4">
                <div className="your-order-product-info">
                    <div className="your-order-top">
                        <ul>
                            <li>Pacchetto</li>
                            <li>{checkout?.piano}</li>
                        </ul>
                    </div>
                    <div className="your-order-middle">
                        <ul>

                            <li key={"tema"}>
                                        <span className="order-middle-left">
                                           Tema
                                        </span>{" "}
                                <span className="order-price">
                                         {checkout?.theme}
                                        </span>
                            </li>
                            <li key={"start"}>
                                        <span className="order-middle-left">
                                           Inizio evento
                                        </span>{" "}
                                <span className="order-price">
                                         {checkout?.start}
                                        </span>
                            </li>
                            <li key={"end"}>
                                        <span className="order-middle-left">
                                           Fine evento
                                        </span>{" "}
                                <span className="order-price">
                                         {checkout?.end}
                                        </span>
                            </li>
                        </ul>
                    </div>

                    <div className="your-order-bottom">
                        <ul>
                            <li className="your-order-shipping">Forma QRCode</li>
                            <li>{checkout?.shape}</li>
                        </ul>
                        {checkout?.piano !== 'basic' &&
                            <>
                                <ul>
                                    <li className="your-order-shipping">Locandina</li>

                                    <li>{checkout?.locandina}</li>


                                </ul>
                                <ul>
                                    <li className="your-order-shipping">Numero di locandine</li>

                                    <li>
                                        {(checkout?.piano === 'medium' ? 15 : 30) + parseInt(checkout?.numeroLocandine)}
                                    </li>

                                </ul>

                            </>
                        }
                    </div>


                    <div className="your-order-total">
                        <div className="your-order-bottom">
                            {checkout?.numeroLocandine !== 0 &&
                                <ul>
                                    <li className="your-order-shipping">Prezzo locandine aggiuntive</li>

                                    <li>                     {"€" +
                                        ((
                                            (parseInt(checkout?.numeroLocandine) / 5 * 1000)
                                        ) / 100).toFixed(2)

                                    }</li>


                                </ul>
                            }
                        </div>
                        <ul>
                            <li className="order-total">{"TOTALE"}</li>
                            <li>
                                {"€" +
                                    ((
                                        (checkout?.piano === 'basic' ? 8999 : checkout?.piano === 'medium' ? 12999 : 15999)
                                        + (parseInt(checkout?.numeroLocandine) / 5 * 1000)
                                    ) / 100).toFixed(2)

                                }

                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DetailsOrdine;