import React from 'react'
import Banner1 from '../../assets/images/snapmemories/home/try_1.png'
import QRcode from '../../assets/images/snapmemories/home/qr_code_try.png'
import Banner2 from '../../assets/images/snapmemories/home/try_2.png'
import {Link} from "react-router-dom";
import Ourvalue from "../Menabout/Ourvalue";


function TryCode() {
    return (
        <>
            <section className="try_code">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-6" data-aos="fade-up" data-aos-duration={1500}>
                            <div className="hero_imgleft">
                                <img src={Banner1} alt="image"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 banner_content_colom" data-aos="fade-up"
                             data-aos-duration={1500}>
                            <div className="banner_text">
                                <h1>Provalo</h1>
                                <h2>
                                    Prima di comprare sei <span>curioso</span> di vedere come funziona?
                                </h2>

                                <div
                                    className="qrcode"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        flexDirection: "column"
                                    }}
                                >
                                    <p style={{fontSize:"21px"}}>Scannerizza il qr code</p>
                                    <div
                                        className="qrcode-container"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '200px', // Puoi regolare questa dimensione secondo le tue necessità
                                            height: '200px', // Assicurati che sia uguale alla larghezza per mantenere il div quadrato
                                            overflow: 'hidden',
                                            borderRadius: "20px"
                                        }}
                                    >
                                        <img
                                            src={QRcode}
                                            alt="image"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                padding: "15px"
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className="button_qr"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        flexDirection: "column",
                                        marginTop:"35px"
                                    }}
                                >

                                    <div className="btn_block" >
                                        <Link to="https://app.snapmemories.it/home/0670dedb-978d-463d-ad64-e104c509c873" style={{fontSize:"25px"}} className="btn puprple_btn ml-0">
                                            Clicca qui per collegarti al qrcode!
                                        </Link>
                                        <div className="btn_bottom"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6" data-aos="fade-up" data-aos-duration={1500}>
                            <div className="hero_img_right">
                            <img src={Banner2} alt="image"/>
                            </div>
                        </div>
                    </div>
                    {/*<div className="spinBlock" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="star">
                            <img src={Star1} alt="image" />
                        </span>
                        <div className="spin_box" id="scrollButton">
                            <span className="downsign">
                                <i className="icofont-long-arrow-down" />
                            </span>
                            <div className="spin-text">
                                <img src={Text} alt="image" />
                            </div>
                        </div>
                        <span className="star">
                            <img src={Star1} alt="image" />
                        </span>
                    </div>*/}
                </div>
            </section>
        </>
    )
}

export default TryCode