


// Crea il vettore delle foto
const photos = [
  { src: process.env.PUBLIC_URL + '/assets/images/wedding/foto1.jpg', width: 2, height: 3 },
  { src: process.env.PUBLIC_URL + '/assets/images/wedding/foto3.png', width: 1, height: 1 },
  { src: process.env.PUBLIC_URL + '/assets/images/wedding/foto4.jpg', width: 3, height: 4 },
  { src: process.env.PUBLIC_URL + '/assets/images/wedding/foto3.jpg', width: 3, height: 4 },
  { src: process.env.PUBLIC_URL + '/assets/images/wedding/foto5.jpg', width: 3, height: 4 },
  { src: process.env.PUBLIC_URL + '/assets/images/wedding/foto6.jpg', width: 3, height: 4 }
  // aggiungi altri oggetti foto come necessario
];

// Esporta il vettore delle foto
export default photos;