import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import slideHome0 from '../../assets/images/snapmemories/home/home-5.jpg';
import slideHome1 from '../../assets/images/snapmemories/home/home-6.jpg';
import slideHome3 from '../../assets/images/snapmemories/home/home-9.jpg';
import slideHome4 from '../../assets/images/snapmemories/home/home-8.jpg';
import slideHome5 from '../../assets/images/snapmemories/home/home-7.jpg';
import slideHome6 from '../../assets/images/snapmemories/home/home-11.jpg';
import slideHome7 from '../../assets/images/snapmemories/home/home-10.jpg';


const abtSlideImages = [
    slideHome0,
    slideHome1,
    slideHome3,
    slideHome4,
    slideHome5,
    slideHome6,
    slideHome7,
];

function AboutSectionHome() {
    return (
        <>
            <section className="about_us_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        {/* <span className="title_badge">Su di noi</span>*/}
                        <h2 style={{
                            fontSize: "45px"
                        }}>
                            <span>Snap Memories</span> non è solo un raccoglitore di foto
                        </h2>
                        {/*<p>
                            E' una galleria interattiva che consente agli invitati del tuo evento di condividere facilmente foto e video,
                            senza dover installare alcuna applicazione sul loro telefono.
                        </p>*/}
                    </div>
                </div>
                <div className="about_slider row_am" data-aos="fade-in" data-aos-duration={1500}>
                    <Swiper
                        className="swiper-wrapper"
                        id="about_slider"
                        pagination={{clickable: true}}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            600: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1000: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                            1400: {
                                slidesPerView: 4,
                                spaceBetween: 40,
                            },
                        }}
                    >
                        {abtSlideImages.map((image, index) => (
                            <SwiperSlide key={index} className="item">
                                <div className="abt_slides">
                                    <img src={image} alt="image"/>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="container">
                    <div className="row abt_text" data-aos="fade-up" data-aos-duration={1500}>
                        <div className="col-md-5">
                            <h2>
                                {/* La piattaforma <span>social</span> dedicata per il tuo evento*/}
                                È la soluzione <span>perfetta </span> per immortalare ogni momento del tuo giorno
                                speciale
                            </h2>
                        </div>
                        <div className="col-md-7">
                            <p style={{fontSize: "20px"}}>
                                Snapmemories è una galleria interattiva che consente agli invitati del tuo evento di
                                condividere facilmente foto e video,
                                senza dover installare alcuna applicazione sul loro telefono.<br/>

                                La piattaforma offre anche funzionalità interattive, permettendo agli ospiti di
                                "reagire" ai contenuti. Questo non solo aumenta l'interazione, ma incentiva gli invitati
                                a pubblicare di più,
                                grazie a una classifica in tempo reale delle foto che ricevono il maggior numero di
                                reazioni.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutSectionHome;
