import React, {useState} from 'react';
import BannerVideo from "./components/BannerVideo";

function ConfigureFotoVideo({ handleTabChange}) {

    return (
        <>
            <BannerVideo handleTabChange={handleTabChange}/>
        </>
    );
}

export default ConfigureFotoVideo;
