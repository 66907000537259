import React, {useEffect, useState} from 'react';
// import screen from "../../assets/images/sign_in_screen.png";
import homeDark from "../../assets/images/snapmemories/app/home-dark.png";
import grigliaDark from "../../assets/images/snapmemories/app/griglia-dark.png";
import notificheDark from "../../assets/images/snapmemories/app/notifiche-dark.png";
import postDark from "../../assets/images/snapmemories/app/post-dark.png";
import profiloDark from "../../assets/images/snapmemories/app/profilo-dark.png";
import descrizioneDark from "../../assets/images/snapmemories/app/descrizione-dark.png";

import homeGlass from "../../assets/images/snapmemories/app/home-glass.png";
import grigliaGlass from "../../assets/images/snapmemories/app/griglia-glass.png";
import notificheGlass from "../../assets/images/snapmemories/app/notifiche-glass.png";
import postGlass from "../../assets/images/snapmemories/app/post-glass.png";
import profiloGlass from "../../assets/images/snapmemories/app/profilo-glass.png";
import descrizioneGlass from "../../assets/images/snapmemories/app/descrizione-glass.png";

import homeLight from "../../assets/images/snapmemories/app/home-light.png";
import grigliaLight from "../../assets/images/snapmemories/app/griglia-light.png";
import notificheLight from "../../assets/images/snapmemories/app/notifiche-light.png";
import postLight from "../../assets/images/snapmemories/app/post-light.png";
import profiloLight from "../../assets/images/snapmemories/app/profilo-light.png";
import descrizioneLight from "../../assets/images/snapmemories/app/descrizione-light.png";

import Slider from "react-slick";
import {isMobile} from "react-device-detect";

function ConfigureEvent({checkout, handleTabChange}) {

    const [color, setColor] = useState(checkout?.theme ? checkout?.theme : 'blue');
    const [name, setName] = useState(checkout?.name ? checkout?.name : '');
    const [start, setStart] = useState(checkout?.start ? checkout?.start : '');
    const [end, setEnd] = useState(checkout?.end ? checkout?.end : '');
    const [errors, setErrors] = useState({});

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : 3,
        slidesToScroll: 1,
    };

    const onFinish = () => {
        let validationErrors = {};

        if (!name) validationErrors.name = "Il nome dell'evento è obbligatorio.";
        if (!start) validationErrors.start = "La data di inizio è obbligatoria.";
        if (!end) validationErrors.end = "La data di fine è obbligatoria.";

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setErrors({});

        let partsStart = start.split("-");
        let partsEnd = end.split("-");
        let formattedDateStart = `${partsStart[2]}/${partsStart[1]}/${partsStart[0]}`;
        let formattedDateEnd = `${partsEnd[2]}/${partsEnd[1]}/${partsEnd[0]}`;

        handleTabChange({c: true}, {
            name: name,
            theme: color,
            start: formattedDateStart,
            end: formattedDateEnd
        }, "event");
    };

    const getMinEndDate = () => {
        if (start) {
            const startDate = new Date(start);
            startDate.setDate(startDate.getDate() + 1);
            return startDate.toISOString().split('T')[0];
        } else {
            const today = new Date();
            today.setDate(today.getDate() + 2);
            return today.toISOString().split('T')[0];
        }
    };

    useEffect(() => {
        if (!checkout?.name) {
            setName('')
            setStart('')
            setEnd('')
            setColor('blue')
            setErrors({});
        }
    }, [checkout])

    return (
        <>
            <section className="row_am interface_section" style={{display: "flex", paddingTop: "0"}}>
                <div className="container">
                    <div className="blog_listings">
                        <div style={{
                            padding: isMobile ? "30px 30px 10vh" : "30px 80px 10vh",
                            borderRadius: "20px",
                            backgroundColor: "white"
                        }}
                             data-aos="fade-up"
                             data-aos-duration={1500}>
                            <div className="authore_info"
                                 style={{
                                     width: "auto",
                                     margin: "auto",
                                     marginBottom: "50px"
                                 }}>
                                <div className="section_title" data-aos="fade-up" data-aos-duration={1500}
                                     data-aos-delay={300}>
                                    <span className="title_badge">Step numero 2</span>
                                </div>
                                <div className="form_block">
                                    <div className="form_side">
                                        <form>
                                            <div className="form-group">
                                                <div>
                                                    {checkout?.piano === "basic" ?
                                                        <div>
                                                            Il pacchetto selezionato non prevede la
                                                            personalizzazione del
                                                            tema. <br/>
                                                            Passa ad un piano "Medium" o "Premium" per abilitare
                                                            questa funzione.
                                                        </div>

                                                        :

                                                        <div className="btn-group btn-group-toggle"
                                                             data-toggle="buttons">
                                                            <div style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                marginTop: "20px",
                                                                marginBottom: "20px",
                                                                flexDirection: isMobile ? "column" : "row",
                                                            }}>
                                                                <div style={{
                                                                    minWidth: !isMobile ? "280px" : "none"
                                                                }}> Seleziona il tema:
                                                                </div>
                                                                <div>
                                                                    <label onClick={() => setColor('blue')}
                                                                           className={"btn btn-blue " + (color === 'blue' ? 'active' : '')}
                                                                           style={{
                                                                               borderRadius: "30px",
                                                                               width: "50px",
                                                                               height: "50px",
                                                                               margin: 5,
                                                                           }}
                                                                           defaultChecked={color === 'blue'}
                                                                    >
                                                                    </label>
                                                                    <label onClick={() => setColor('glass')}
                                                                           className={"btn btn-green " + (color === 'glass' ? 'active' : '')}
                                                                           style={{
                                                                               borderRadius: "30px",
                                                                               width: "50px",
                                                                               height: "50px",
                                                                               margin: 5,
                                                                           }}
                                                                           defaultChecked={color === 'glass'}
                                                                    >
                                                                    </label>

                                                                    <label onClick={() => setColor('light')}
                                                                           className={"btn btn-light " + (color === 'light' ? 'active' : '')}
                                                                           style={{
                                                                               borderRadius: "30px",
                                                                               width: "50px",
                                                                               height: "50px",
                                                                               margin: 5,
                                                                           }}
                                                                           defaultChecked={color === 'light'}
                                                                    >
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>


                                                <div style={{
                                                    display: "flex",
                                                    alignItems: isMobile ? "baseline" : "center",
                                                    marginTop: "20px",
                                                    marginBottom: errors.name ? "0px" : "20px",
                                                    justifyContent: "center",
                                                    flexDirection: isMobile ? "column" : "row",
                                                }}>
                                                    <div
                                                        style={{
                                                            minWidth: !isMobile ? "280px" : "none"
                                                        }}> Nome evento:
                                                    </div>
                                                    <input
                                                        required
                                                        onChange={(e) => setName(e.target.value)}
                                                        type="name"
                                                        className="form-control"
                                                        defaultValue={name}
                                                        value={name}
                                                        placeholder="Inserisci il nome dell'evento"/>
                                                </div>
                                                {errors.name && <div style={{color: 'red'}}>{errors.name}</div>}

                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: isMobile ? "baseline" : "center",
                                                    marginTop: "20px",
                                                    marginBottom: errors.start ? "0px" : "20px",
                                                    flexDirection: isMobile ? "column" : "row"
                                                }}>
                                                    <div
                                                        style={{
                                                            minWidth: !isMobile ? "280px" : "none",
                                                        }}> Inserisci la data di inizio dell'evento:
                                                    </div>
                                                    <input type="date"
                                                           className="form-control"
                                                           id="dateStart" name="start"
                                                           min={new Date(Date.now() + (86400000)).toISOString().split('T')[0]}
                                                           defaultValue={start}
                                                           value={start}
                                                           onChange={(e) => setStart(e.target.value)}
                                                    />

                                                </div>
                                                {errors.start && <div style={{color: 'red'}}>{errors.start}</div>}
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: isMobile ? "baseline" : "center",
                                                    marginTop: "20px",
                                                    marginBottom: errors.end ? "0px" : "20px",
                                                    flexDirection: isMobile ? "column" : "row"
                                                }}>
                                                    <div
                                                        style={{
                                                            minWidth: !isMobile ? "280px" : "none",
                                                        }}> Inserisci la data di fine dell'evento:
                                                    </div>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        id="dateEnd"
                                                        name="end"
                                                        min={getMinEndDate()}
                                                        defaultValue={end}
                                                        value={end}
                                                        onChange={(e) => setEnd(e.target.value)}
                                                    />
                                                </div>
                                                {errors.end && <div style={{color: 'red'}}>{errors.end}</div>}

                                            </div>
                                            <div className="side_screen" style={{
                                                display: "flex"
                                            }}>

                                            </div>

                                        </form>
                                    </div>
                                </div>

                            </div>
                            <Slider {...settings}>
                                <div className="scrren">
                                    <img
                                        src={color === 'blue' ? homeDark : color === 'glass' ? homeGlass : homeLight}
                                        alt="image" style={{
                                        border: "5px solid black",
                                        borderRadius: "15px",
                                        width: isMobile ? "25vh" : "27vh",
                                        height: isMobile ? "55vh" : "57vh"
                                    }}/>
                                </div>

                                <div className="scrren">
                                    <img
                                        src={color === 'blue' ? grigliaDark : color === 'glass' ? grigliaGlass : grigliaLight}
                                        alt="image" style={{
                                        border: "5px solid black",
                                        borderRadius: "15px",
                                        width: isMobile ? "25vh" : "27vh",
                                        height: isMobile ? "55vh" : "57vh"
                                    }}/>
                                </div>

                                <div className="scrren">
                                    <img
                                        src={color === 'blue' ? notificheDark : color === 'glass' ? notificheGlass : notificheLight}
                                        alt="image" style={{
                                        border: "5px solid black",
                                        borderRadius: "15px",
                                        width: isMobile ? "25vh" : "27vh",
                                        height: isMobile ? "55vh" : "57vh"
                                    }}/>
                                </div>

                                <div className="scrren">
                                    <img
                                        src={color === 'blue' ? profiloDark : color === 'glass' ? profiloGlass : profiloLight}
                                        alt="image" style={{
                                        border: "5px solid black",
                                        borderRadius: "15px",
                                        width: isMobile ? "25vh" : "27vh",
                                        height: isMobile ? "55vh" : "57vh"
                                    }}/>
                                </div>

                                <div className="scrren">
                                    <img
                                        src={color === 'blue' ? postDark : color === 'glass' ? postGlass : postLight}
                                        alt="image" style={{
                                        border: "5px solid black",
                                        borderRadius: "15px",
                                        width: isMobile ? "25vh" : "27vh",
                                        height: isMobile ? "55vh" : "57vh"
                                    }}/>
                                </div>
                                <div className="scrren">
                                    <img
                                        src={color === 'blue' ? descrizioneDark : color === 'glass' ? descrizioneGlass : descrizioneLight}
                                        alt="image" style={{
                                        border: "5px solid black",
                                        borderRadius: "15px",
                                        width: isMobile ? "25vh" : "27vh",
                                        height: isMobile ? "55vh" : "57vh"
                                    }}/>
                                </div>
                            </Slider>
                            <div className="row" style={{
                                paddingTop: "60px"
                            }}>
                                <div className="col-lg-5 col-3">
                                </div>
                                <div className="col-lg-2 col-6">

                                    <div className="btn_block">
                                        <div>
                                            <button onClick={() => onFinish()}
                                                    className="btn puprple_btn ml-0">Continua
                                            </button>
                                            <div className="btn_bottom"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-3">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ConfigureEvent;