import React, {useEffect, useState} from 'react'

// Import React FilePond
import {FilePond, registerPlugin} from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType)

// Our app
function UploadComponent({setUploadFiles, media, file}) {
    const [files, setFiles] = useState([])

    const setFilesOnChange = (files) => {
        setFiles(files)
        setUploadFiles(files, media)
    }

    useEffect(() => {
        if (file === '') {
            setFiles([])
        }
    }, [file]);

    return (
        <div className="App">
            <FilePond
                files={files}
                onupdatefiles={setFilesOnChange}
                allowMultiple={false}
                maxFiles={1}
                name="files" /* sets the file input name, it's filepond by default */
                labelIdle='Drag & Drop i tuoi file o <span class="filepond--label-action">Carica da qui</span>'
                acceptedFileTypes={media === 'video' ? [
                        'video/mp4',
                        'video/quicktime', // .mov
                        'video/x-m4v',     // .m4v
                        'video/x-msvideo', // .avi
                        'video/x-ms-wmv',  // .wmv
                        'video/x-matroska',// .mkv
                        'video/webm',      // .webm
                        'video/ogg',       // .ogv
                        'video/x-flv'      // .flv
                    ] :
                    [
                        'image/jpeg',      // .jpg, .jpeg
                        'image/png',       // .png
                        'image/gif',       // .gif
                        'image/bmp',       // .bmp
                        'image/webp',      // .webp
                        'image/tiff',      // .tiff
                        'image/x-icon',    // .ico
                        'image/heif',      // .heif (used by iPhone)
                        'image/heic'       // .heic (used by iPhone)

                    ]
                }
            />
        </div>
    )
}

export default UploadComponent;