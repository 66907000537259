import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import blue_dotes from '../../assets/images/blue_dotes.png'
import screenBacheca from '../../assets/images/snapmemories/home/home-feature-1.png'
import screebReazioni from '../../assets/images/snapmemories/home/home-feature-2.png'
import screenClassifica from '../../assets/images/snapmemories/home/home-feature-3.png'

const Feature = {
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
        1400: {
            margin: 60,
        },
    },
};

const featureItems = [
    {
        title: 'Bacheca',
        description: 'Bacheca sempre aggiornata con tutte le foto e i video dell\'evento',
        image: screenBacheca,
    },
    {
        title: 'Reazioni',
        description: 'Reagisci con l’emoticon che preferisci alle foto che ti piacciono di più',
        image: screebReazioni,
    },
    {
        title: 'Classifica',
        description: 'Classifica aggiornata in tempo reale delle foto che ricevono più reazioni',
        image: screenClassifica,
    }
];

function KeyfeatureHome() {
    return (
        <>
            <section className="key_feature_section row_am pt-0">
                <div className="key_innner">
                    <div className="container">
                        <div className="dotes_blue">
                            <img src={blue_dotes} alt="image" />
                        </div>
                        <div className="section_title">
                            <span className="title_badge">Funzionalità chiave</span>
                            <h2 >Tutto a portata di mano</h2>
                        </div>
                        <Swiper
                            id="feature_slider"
                            className="swiper-wrapper"
                            data-aos="fade-up"
                            data-aos-duration={1500}
                            {...Feature}
                            slidesPerView={3}
                            spaceBetween={15}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 15,
                                },
                                600: {
                                    slidesPerView: 1,
                                    spaceBetween: 15,
                                },
                                1000: {
                                    slidesPerView: 3,
                                    spaceBetween: 15,
                                },
                                1400: {
                                    slidesPerView: 3,
                                    spaceBetween: 15,
                                },
                            }}

                        >
                            {featureItems.map((item, index) => (
                                <SwiperSlide className="item" key={index}>
                                    <div className="feature_box">
                                        <h3 style={{fontSize:"30px"}}>{item.title}</h3>
                                        <p style={{fontSize:"21px"}}>{item.description}</p>
                                        <div className="img">
                                            <img
                                                style={{
                                                    width: index === 1 ? "80%" : "100%",
                                                    height:"100%",
                                                    borderTopRightRadius: "20px",
                                                    borderTopLeftRadius: "20px"
                                            }}
                                                src={item.image}
                                                alt="image"
                                            />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    );
}

export default KeyfeatureHome;
