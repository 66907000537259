import React from 'react'
import Dots from '../../assets/images/blue_dotes.png'
import Email from '../../assets/images/email_icon.png'
import {Link} from "react-router-dom";

function Resources() {
    return (
        <>
            <section className="our_resource">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="section_title" data-aos="fade-up" data-aos-duration={1500} >
                                {/* <span className="title_badge">Locandine</span>*/}
                                <h2 style={{marginBottom:"20px"}}>
                                    Scegli le nostre <span>locandine</span>
                                </h2>
                                <p style={{fontSize:"21px"}}>
                                    Scegli il tuo stile preferito tra le numerose locandine disponibili e riceverai il tuo ordine già stampato con il QR code personalizzato.
                                </p>
                                <p style={{fontSize:"21px"}}>
                                    Scopri la nostra vasta gamma di design per locandine, pensati per ogni tipo di evento.
                                    Che tu stia organizzando una festa, un matrimonio o qualsiasi altra occasione speciale,
                                    troverai sicuramente il modello che meglio rappresenta il tuo evento.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mail_block white_text" data-aos="fade-up" data-aos-duration={1500} >
                                <div className="dotes_blue">
                                    <img src={Dots} alt="image" />
                                </div>
                                <div className="text">
                                    <span className="icon">
                                        <img src={Email} alt="image"/>
                                    </span>
                                    <h3>Non hai trovato nessuna locandina di tuo gradimento?</h3>
                                    <p>Contattaci e faremo il possibile per soddisfare ogni tua richiesta</p>
                                    <div className="btn_block">
                                        <Link to="/contact" className="btn puprple_btn ml-0" style={{fontSize: "25px"}}>
                                            Contattaci
                                        </Link>
                                        <div className="btn_bottom"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Resources