import React, {useEffect, useState} from 'react';
import AereoSvg from "./components/AereoSvg";
import PalloncinoSvg from "./components/PalloncinoSvg";
import BicchiereSvg from "./components/BicchiereSvg";
import TortaSvg from "./components/TortaSvg";
import CameraSvg from "./components/CameraSvg";
import StandardSvg from "./components/StandardSvg";
import {isMobile} from "react-device-detect";

function ConfigureEvent({checkout, handleTabChange}) {

    const [shape, setShape] = useState('standard')
    const [locandina, setLocandina] = useState("0")
    const [numeroLocandine, setNumeroLocandine] = useState(0)
    const [errors, setErrors] = useState({});

    const onFinish = () => {

        let validationErrors = {};

        if (locandina === "0") validationErrors.locandina = "Seleziona una locandina per l'evento.";

        if (Object.keys(validationErrors).length > 0) {
            console.log(errors)
            setErrors(validationErrors);
            return;
        }

        // Clear errors if validation passes
        setErrors({});

        handleTabChange({d: true}, {shape: shape, locandina: locandina, numeroLocandine: numeroLocandine}, "qrcode")
    }

    useEffect(() => {
        if (!checkout?.shape) {
            console.log("IFFFF")
            setShape('standard')
            setLocandina("0")
            setNumeroLocandine(0)
            setErrors({});
        }
    }, [checkout])
    return (
        <section>
            <div className="how_it_inner" style={{
                backgroundColor: "white",
                padding: "30px"
            }}>
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge">Step numero 3</span>
                    </div>
                    <div className="text">
                        <h3>Scegli la forma del qrcode</h3>
                        <p></p>
                    </div>
                    <div className="row" style={{marginBottom: "30px"}}>

                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <StandardSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="standard"
                                               defaultChecked={shape === 'standard'}
                                               checked={shape === 'standard'}
                                               onChange={() => setShape('standard')}
                                        />
                                        <label htmlFor="qrcode"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <AereoSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="aereo"
                                               onChange={() => setShape('aereo')}
                                               defaultChecked={shape === 'aereo'}

                                        />
                                        <label htmlFor="aereo"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <PalloncinoSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="palloncino"
                                               onChange={() => setShape('palloncino')}
                                               defaultChecked={shape === 'palloncino'}
                                        />
                                        <label htmlFor="palloncino"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <BicchiereSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="bicchiere"
                                               onChange={() => setShape('bicchiere')}
                                               defaultChecked={shape === 'bicchiere'}
                                        />
                                        <label htmlFor="bicchiere"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <TortaSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="torta"
                                               onChange={() => setShape('torta')}
                                               defaultChecked={shape === 'torta'}
                                        />
                                        <label htmlFor="torta"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <CameraSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="camera"
                                               onChange={() => setShape('camera')}
                                               defaultChecked={shape === 'camera'}
                                        />
                                        <label htmlFor="camera"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form>
                        <div className="text">
                            <h3>Scegli la locandina su cui stampare il qrcode</h3>
                            <p>
                                Puoi visualizzare le locandine disponibili &nbsp;
                                <a href="/banner" target="_blank" rel="noopener noreferrer" class="hyperlink">
                                    cliccando qui.
                                </a>

                            </p>
                        </div>
                        <div className="form-group">

                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: isMobile ? "column" : "row"
                            }}>
                                <div
                                    style={{
                                        minWidth: !isMobile ? "450px" : "none",
                                        marginTop: "20px",
                                        marginBottom: "20px"
                                    }}> Inserisci la tipologia di locandina:
                                </div>
                                <select
                                    className="form-control"
                                    id="locandine"
                                    name="locandine"
                                    onChange={(e) => setLocandina(e.target.value)}
                                    defaultValue={locandina}
                                    value={locandina}
                                >
                                    <option value="0" disabled>Seleziona una locandina</option>
                                    <option value="Party">Party</option>
                                    <option value="Old Rose Floral">Old Rose Floral</option>
                                    <option value="Green Floral">Green Floral</option>
                                    <option value="Old Rose Leaves">Old Rose Leaves</option>
                                    <option value="Gold Leaves">Gold Leaves</option>
                                </select>

                            </div>
                            {errors.locandina && <div style={{color: 'red'}}>{errors.locandina}</div>}

                        </div>
                        <p>
                            <strong> Attenzione! </strong>Il pacchetto scelto prevede la realizzazione
                            di {checkout.piano === "medium" ? 15 : 30} locandine. <br/>

                        </p>
                        <div className="form-group-2">

                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: isMobile ? "column" : "row",
                                marginTop: "20px",
                                marginBottom: "20px"

                            }}>
                                <div
                                    style={{
                                        minWidth: !isMobile ? "450px" : "none",
                                    }}> Inserisci il numero di locandine extra:
                                </div>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="numberLocandine"
                                    name="numberLocandine"
                                    defaultValue={numeroLocandine}
                                    value={numeroLocandine}
                                    min={"0"}
                                    step="5"
                                    onChange={(e) => setNumeroLocandine(e.target.value)}
                                />
                            </div>
                            <p>
                                L'aggiunta di ogni 5 locandine prevede un sovrapprezzo di €10.00 al pacchetto scelto.
                            </p>
                        </div>

                    </form>


                    <div className="row" style={{
                        paddingTop: "30px"
                    }}>
                        <div className="col-lg-5 col-3">
                        </div>
                        <div className="col-lg-2 col-6">

                            <div className="btn_block">
                                <div>
                                    <button onClick={() => onFinish()}
                                            className="btn puprple_btn ml-0">Continua
                                    </button>
                                    <div className="btn_bottom"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-3">
                        </div>
                    </div>

                </div>
            </div>

        </section>

    );
}

export default ConfigureEvent;
