import React from 'react';
import { Link } from 'react-router-dom';
import matrimonio from '../../assets/images/snapmemories/home/snap-memories-home-example-5.jpg';
import laurea from '../../assets/images/snapmemories/home/snap-memories-home-example-3.jpg';
import compleanno from '../../assets/images/snapmemories/home/snap-memories-home-example-6.jpeg';
import corporateEvents from '../../assets/images/snapmemories/home/snap-memories-home-example-8.png';

const reviews = [
    {
        id: 1,
        name: 'Matrimonio',
        company: 'Smartbrain Tech',
        stars: 5,
        text: 'Trasforma il tuo grande giorno in una gara a chi riesce a catturare la foto più bella, così da poter conservare ogni singolo momento speciale del tuo matrimonio.',
        avatar: matrimonio,
    },
    {
        id: 2,
        name: 'Laurea',
        company: 'Corporate Agency',
        stars: 5,
        text: 'Stai pianificando la tua festa di laurea? Snapmemories è qui per aiutarti a immortalare i momenti più belli del tuo evento. È il modo perfetto per coinvolgere i tuoi ospiti e creare ricordi che dureranno per sempre!',
        avatar: laurea,
    },
    {
        id: 3,
        name: 'Compleanno',
        company: 'Company Inc',
        stars: 5,
        text: 'Con snapmemories puoi creare una galleria di foto e video personalizzata per il tuo compleanno. Puoi condividerla con la tua famiglia e i tuoi amici, oppure conservarla per rivivere il tuo evento ogni volta che vuoi!',
        avatar: compleanno,
    },
    {
        id: 4,
        name: 'Evenzi aziendali',
        company: 'Smartbrain Tech',
        stars: 5,
        text: 'Noi di snapmemories sappiamo quanto sia fondamentale organizzare eventi aziendali che siano sia coinvolgenti e divertenti. Grazie a snapmemories, potrete stupire i vostri ospiti lasciandoli senza parole.',
        avatar: corporateEvents,
    },
];

function OccasioniHome() {
    return (
        <section className="use_case_section row_am page_ban">
            <div className="">
                <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                    <h2>
                        Per tutte le <span>occasioni</span>
                    </h2>
                   {/* <p>
                        Lorem Ipsum is simply dummy text of the printing indus orem Ipsum <br />
                        has been the industrys standard dummy text ever since.
                    </p>*/}
                </div>
                <div className="positive_inner in_page">
                    <div className="review_grid">
                        {reviews.map((review) => (
                            <div
                                key={review.id}
                                className="review_block"
                                data-aos="fade-up"
                                data-aos-duration={1500}
                                style={{margin:"20px"}}
                            >
                                <div className="coustomer_info">
                                    <div className="avtar">
                                        <img src={review.avatar} alt={review.name}/>

                                    </div>
                                    <div className="text">
                                        <h3 style={{fontSize:"20px"}}>{review.name}</h3>
                                    </div>
                                </div>
                                <p style={{fontSize:"18px"}}>{review.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OccasioniHome;
