import React, {useEffect} from 'react'
import Header from '../Header/Main'
import HowWork from '../Menhome/HowWork'
import Aos from 'aos'
import Pricingblock from "../Menpricing/Pricingblock";
import Steps from "./Steps";

function ProductShop() {

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

    return (
        <>
            <Header/>
            <HowWork/>
            <Steps />
        </>
    )
}

export default ProductShop