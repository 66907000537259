const sviluppo = {
    API_ENDPOINT_SERVER_MAFFLE: 'https://5p8slj28cj.execute-api.eu-central-1.amazonaws.com/latest',
    S3_BUCKET: 'snapmemories-sviluppo',
    STRIPE_CONFIG: {
        basic: 'price_1PWwrgJ5Q4L2IzvQ4THpxTmF',
        medium: 'price_1PXoHgJ5Q4L2IzvQcj7lrBHq',
        premium: 'price_1PXoI8J5Q4L2IzvQirPKKbA2',
        locandina: 'price_1PXoAvJ5Q4L2IzvQonM7VIQ3'
    }
};

const collaudo = {
    API_ENDPOINT_SERVER_MAFFLE: 'https://5p8slj28cj.execute-api.eu-central-1.amazonaws.com/latest',
    S3_BUCKET: 'snapmemories-sviluppo',
    STRIPE_CONFIG: {
        basic: 'price_1PWwrgJ5Q4L2IzvQ4THpxTmF',
        medium: 'price_1PXoHgJ5Q4L2IzvQcj7lrBHq',
        premium: 'price_1PXoI8J5Q4L2IzvQirPKKbA2',
        locandina: 'price_1PXoAvJ5Q4L2IzvQonM7VIQ3'
    }
};

const produzione = {
    API_ENDPOINT_SERVER_MAFFLE: 'https://yrq0qnl8rh.execute-api.eu-central-1.amazonaws.com/latest',
    S3_BUCKET: 'snapmemories-produzione',
    STRIPE_CONFIG: {
        basic: 'price_1PflgzJ5Q4L2IzvQ9Ss4Pe4b',
        medium: 'price_1PfliCJ5Q4L2IzvQsTcreJZ0',
        premium: 'price_1PfliUJ5Q4L2IzvQ3XO2evoL',
        locandina: 'price_1Pfls1J5Q4L2IzvQYLBmtymW'
    }
};

const getEnv = () => {
    switch (process.env.REACT_APP_ENV?.trim()) {
        case 'sviluppo':
            return sviluppo;
        case 'collaudo':
            return collaudo;
        case 'produzione':
            return produzione;
        default:
            return {
                API_ENDPOINT_SERVER_MAFFLE: 'http://localhost:8080',
                S3_BUCKET: 'snapmemories-sviluppo'
            };
    }
}

export const env = getEnv();
