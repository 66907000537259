import React, {useEffect, useState} from 'react'
import Bluedots from '../../assets/images/blue_dotes.png'
import axios from "axios"
import {env} from '../../configs/EnvironmentConfig';

function Contactform() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        country: '',
        phone: '',
        descrizione: '',
        subject: ''
    });
    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dots, setDots] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        let intervalId;

        if (isSubmitting && isSubmittedSuccessfully === null) {
            intervalId = setInterval(() => {
                setDots(prevDots => prevDots === '...' ? '' : prevDots + '.');
            }, 500);
        } else {
            clearInterval(intervalId);
            setDots('');
        }

        return () => clearInterval(intervalId);
    }, [isSubmitting, isSubmittedSuccessfully]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        if (!validateEmail(formData.email)) {
            setErrorMessage('Inserisci un indirizzo email valido.');
            return;
        }

        setIsSubmitting(true);
        const body = {
            "nome": formData.name,
            "telefono": formData.phone,
            "descrizione": formData.descrizione,
            "mail": formData.email,
            "tipologia": "MAIL_TO_CLIENTE",
            "destinatario": formData.email,
            "oggetto": formData.subject
        };

        try {
            const response = await axios.post(env.API_ENDPOINT_SERVER_MAFFLE + "/snapshop/send-email", body)
            if (response.status === 200) {
                setIsSubmittedSuccessfully(true);
                alert('Email inviata con successo!');
            } else {
                setIsSubmittedSuccessfully(false);
                setErrorMessage('Qualcosa non è andato, riprova!');
            }
        } catch (e) {
            console.error(e)
            setIsSubmittedSuccessfully(false)
            setErrorMessage('Qualcosa non è andato, riprova!');
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                setIsSubmittedSuccessfully(null);
            }, 4000);
        }
    };


    return (
        <>
            <section className="contact_form white_text row_am" data-aos="fade-in" data-aos-duration={1500}>
                <div className="contact_inner">
                    <div className="container">
                        <div className="dotes_blue">
                            <img src={Bluedots} alt="image"/>
                        </div>
                        <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={100}>
                            <span className="title_badge">Contattaci</span>
                            <h2>Contattaci</h2>
                            <p>Riempi il form sotto, il nostro team ti ricontatterà presto</p>
                        </div>
                        <form onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration={1500}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Nome" required
                                               name='name' value={formData.name}
                                               onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="Email" required
                                               name='email' value={formData.email}
                                               onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Oggetto" required
                                               name='subject' value={formData.subject}
                                               onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="phone" className="form-control" placeholder="Cellulare"
                                               name='phone' value={formData.phone} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea type="descrizione" className="form-control" placeholder="Descrizione"
                                                  name='descrizione' onChange={handleChange}
                                                  value={formData.descrizione}/>
                                    </div>
                                </div>
                                <div className="col-md-12 text-right">
                                    <div className="btn_block">
                                        <button className="btn puprple_btn ml-0" disabled={isSubmitting}>
                                            Invio
                                            {isSubmitting && isSubmittedSuccessfully === null && (
                                                <div>{"in corso"}{dots}</div>
                                            )}
                                        </button>
                                        <div className="btn_bottom"/>
                                    </div>
                                    {errorMessage && (
                                        <div className="error-banner">
                                            {errorMessage}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contactform;
