import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import logo from '../../assets/images/logonero.png'

function Main() {

    const [headerScroll, setheaderScroll] = useState(false)
    const [mobile, setmobile] = useState()
    const [Page, setPage] = useState()

    useEffect(() => {

        const fixedHeader = () => {
            if (window.pageYOffset > 150) {
                setheaderScroll(true)
            } else {
                setheaderScroll(false)
            }
        }
        window.addEventListener('scroll', fixedHeader)
    }, [])

    return (
        <>
            <header className={headerScroll ? "fixed fix_style" : "fixed"}>
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <Link className="navbar-brand" to="/">
                            <img src={logo} alt="Logo"/>
                        </Link>
                        {/*Tolgo il menu per il momento*/}
                        <button className={`navbar-toggler ${mobile && "collapsed"}`}
                                onClick={() => setmobile(mobile === true ? false : true)} type="button"
                                data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <div className={`toggle-wrap ${mobile && "active"}`}
                                 onClick={() => setmobile(mobile === true ? false : true)}>
                                <span className="toggle-bar"></span>
                            </div>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/come-funziona">
                                        How it works
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/banner">
                                        Locandine
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact">
                                        Contattaci
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <div className="btn_block">
                                        <Link className="nav-link dark_btn" to="/acquista">
                                            Acquista
                                        </Link>
                                        <div className="btn_bottom"/>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/login">

                                        <svg xmlns="http://www.w3.org/2000/svg" style={{
                                            margin: "2px"
                                        }} width="28" height="28" fill="currentColor"
                                             className="bi bi-person" viewBox="0 0 16 16">
                                            <path
                                                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        {mobile &&
                            <>
                                <div className={`navbar-collapse collapse ${mobile && "show"}`}
                                     id="navbarSupportedContent" style={{}}>
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item active">
                                            <Link className="nav-link" to="/">
                                                Home
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/come-funziona">
                                                How it works
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/banner">
                                                Locandine
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/contact">
                                                Contattaci
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <div className="btn_block">
                                                <Link className="nav-link dark_btn" to="/acquista">
                                                    Acquista
                                                </Link>
                                                <div className="btn_bottom"/>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <div className="btn_block">
                                                <Link className="nav-link dark_btn"
                                                      style={{
                                                          // backgroundColor: "#F9F4EB",
                                                          backgroundColor: "rgb(251 240 222)",
                                                          color: "#202020"
                                                      }}
                                                      to="/login">
                                                    <svg xmlns="http://www.w3.org/2000/svg" style={{
                                                        margin: "2px"
                                                    }} width="28" height="28" fill="currentColor"
                                                         className="bi bi-person" viewBox="0 0 16 16">
                                                        <path
                                                            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                                    </svg>
                                                    Login
                                                </Link>

                                                <div className="btn_bottom" style={{
                                                    border: "1px solid #F9F4EB"
                                                }}/>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </>
                        }
                    </nav>
                </div>
            </header>
        </>
    )
}

export default Main