import React, {useCallback, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import smallStar from '../../../assets/images/smallStar.png'
import hero from '../../../assets/images/copertina.png'
import play from '../../../assets/images/play_white.svg'
import UploadComponent from "./UploadComponent";
import AWS from 'aws-sdk';
import {env} from "../../../configs/EnvironmentConfig";
import {Swiper, SwiperSlide} from "swiper/react";
import Banner9 from "../../../assets/images/snapmemories/profilo.jpg";
import Icon from "../../../assets/images/iphonescren.png";
import {isMobile} from "react-device-detect";
import Icon1 from "../../../assets/images/snapmemories/home/scritta_bianca.png";
import Swal from 'sweetalert2'

// Configura l'SDK AWS con le tue credenziali e la regione
AWS.config.update({
    accessKeyId: "AKIATAO37WVFO2AWJOFC",
    secretAccessKey: "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",
    region: "eu-south-1",
});
//utenzia di ciccio = snapmemories TODO usare un'utenza solo per s3 snapmemories come fatto per jole
const s3 = new AWS.S3();

const Slidernew = {
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 1,
        },
        1000: {
            items: 1,
        },
    },
};

function BannerVideo({handleTabChange}) {

    const [ytban, setytban] = useState(false);
    const [video, setVideo] = useState(false);
    const [image, setImage] = useState(false);

    const videoContainerRef = useRef(null);

    const handlePlayClick = () => {
        setytban(true);
        setTimeout(() => {
            const videoContainer = videoContainerRef.current;
            if (videoContainer.requestFullscreen) {
                videoContainer.requestFullscreen();
            } else if (videoContainer.mozRequestFullScreen) { // Firefox
                videoContainer.mozRequestFullScreen();
            } else if (videoContainer.webkitRequestFullscreen) { // Chrome, Safari and Opera
                videoContainer.webkitRequestFullscreen();
            } else if (videoContainer.msRequestFullscreen) { // IE/Edge
                videoContainer.msRequestFullscreen();
            }

            const videoElement = document.getElementById('youtubevideo');
            videoElement.src = "https://snapmemories-produzione.s3.eu-south-1.amazonaws.com/foto+e+video/TereRafSub.mp4";
            videoElement.style.display = 'block'; // Mostra il video
        }, 300); // Aspetta un po' prima di mettere in fullscreen per assicurarsi che il modale sia visibile
    };

    function formatDate(date) {
        let day = ('0' + date.getDate()).slice(-2); // Add leading zero
        let month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
        let year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const setUploadFiles = useCallback((files, media) => {
        // Ensure files array is not empty and contains a file object
        console.log(files)
        console.log(media)
        if (files && files.length > 0 && files[0].file) {
            console.log(files[0].file);

            const file = files[0].file;

            let formattedDate = formatDate(new Date());

            // Configura i parametri per l'upload su S3
            const params = {
                Bucket: env.S3_BUCKET, // sostituisci con il nome del tuo bucket
                Key: 'copertina-contest/' + formattedDate + '/' + file.name, // nome del file su S3
                Body: file,
                ContentType: file.type
            };

            console.log(params)
            // Carica il file su S3
            s3.upload(params, (err, data) => {
                if (err) {

                    Swal.fire({
                        icon: "error",
                        title: "Oops!",
                        text: "Qualosa è andato storto durante il caricamento! Riprova.",
                    });

                    if (media === 'video') {
                        setVideo(''); // Usa l'URL del file su S3

                    } else {
                        setImage(''); // Usa l'URL del file su S3
                    }
                    console.error('Error uploading file: ', err);
                    return;
                }

                console.log('Successfully uploaded file.', data);

                if (media === 'video') {
                    setVideo(data.Location); // Usa l'URL del file su S3

                } else {
                    setImage(data.Location); // Usa l'URL del file su S3
                }
            });
        } else {
            if (media === 'video') {
                setVideo(null);
            } else {
                setImage(null);
            }
        }
    }, []); // Empty dependency array ensures this function is only created once

    const onFinish = () => {
        handleTabChange({e: true}, {video: video, image: image}, "contest")
    }

    return (
        <>
            <section className="banner_section" style={{
                backgroundColor: "white",
                padding: "30px"
            }}>

                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge">Step numero 4</span>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner_text">
                                <h1>
                                    Carica il tuo <span>contest per l'evento</span>
                                </h1>
                                <p>
                                    Carica un video challenge per il tuo evento, fai sfidare i tuoi partecipanti a suon
                                    di emoticons!
                                </p>
                            </div>
                            <div className="trial_box">
                                <div className="form-group">
                                    <strong>Ricorda: </strong> è possibile inserire il tuo video contest anche
                                    successivamente alla fase
                                    di acquisto, nella tua pagina personale!
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="banner_slider" style={{height: isMobile ? "40vh" : "60vh"}}>
                                <div className="img video_player">
                                    <img src={hero} alt="image" className="hero_img"
                                         style={{width: isMobile ? "24vh" : "42vh", marginTop: "5vh"}}/>
                                    <Link to="#" className="popup-youtube play-button play_icon"
                                          onClick={handlePlayClick}>
                                        <img src={play} alt="img"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <UploadComponent setUploadFiles={setUploadFiles} media={'video'} file={video}/>
                    <div className="spinBlock" data-aos="fade-up">
                        <span className="star">
                            <img src={smallStar} alt="image"/>
                        </span>
                        <div className="spin_box">
                            <div className="">
                                <img src={Icon1} alt="image" style={{
                                    width: "14vh"
                                }}/>
                            </div>
                        </div>
                        <span className="star">
                            <img src={smallStar} alt="image"/>
                        </span>
                    </div>
                    <div className="row" style={{marginTop: "30px", marginBottom: "30px",  flexDirection: "row-reverse"}}>
                        <div className="col-lg-6 col-md-12">
                            <div className="banner_text">
                                <h1>
                                    Carica la tua <span>foto profilo</span>
                                </h1>
                                <p>
                                    Carica una foto simbolica che rispecchi il tema del tuo evento!
                                </p>
                            </div>
                            <div className="trial_box">
                                <div className="form-group">
                                    <strong>Ricorda: </strong> è possibile inserire la tua foto profilo anche
                                    successivamente alla fase
                                    di acquisto, nella tua pagina personale!
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="banner_slider"
                                // style={{height: "65vh"}}
                            >
                                <Swiper
                                    style={{
                                        borderTopLeftRadius: "40px",
                                        borderTopRightRadius: "40px",
                                        borderBottomLeftRadius: "0px",
                                        borderBottomRightRadius: "0px"
                                    }}
                                    id="frmae_slider" className="mySwiper" slidesPerView={1} autoplay={true}
                                    pagination={true}
                                    {...Slidernew} >
                                    <SwiperSlide className="item">
                                        <div className="slider_img">
                                            <img src={Banner9} alt="image"/>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                                <div className="slider_frame">
                                    <img src={Icon} alt="image"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <UploadComponent setUploadFiles={setUploadFiles} media={'image'} file={image}/>

                </div>
                <div className="row" style={{
                    paddingTop: "60px"
                }}>
                    <div className="col-lg-5 col-3">
                    </div>
                    <div className="col-lg-2 col-6">

                        <div className="btn_block">
                            <div>
                                <button onClick={() => onFinish()}
                                        className="btn puprple_btn ml-0">Continua
                                </button>
                                <div className="btn_bottom"/>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-3">
                    </div>
                </div>
            </section>

            {ytban &&
                <div className="modal fade youtube-video show" id="myModal"
                     tabIndex={-1} style={{display: "block", paddingRight: 17}}
                     aria-modal="true" role="dialog" ref={videoContainerRef}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" onClick={() => setytban(false)}>
                            <button id="close-video"
                                    type="button" className="button btn btn-default text-right" data-dismiss="modal"
                                    onClick={() => setytban(false)}>
                                <i className="icofont-close-line-circled"/>
                            </button>
                            <div className="modal-body">
                                <div id="video-container" className="video-container">
                                    <iframe id="youtubevideo"
                                            width={640} height={360} allowFullScreen={true}
                                            src="" // L'URL verrà impostato dinamicamente
                                            style={{display: 'none'}} // Nascondi l'iframe fino a quando non viene cliccato play
                                    />
                                </div>
                            </div>
                            <div className="modal-footer"></div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default BannerVideo