import React, {useEffect, useRef} from 'react'
import Header from '../Header/Main'
import Footer from '../Footer/Main'
import Aos from 'aos'
import StepsHome from "../Menhome/StepsHome";
import AboutSectionHome from "../Menhome/AboutSectionHome";
import TryCode from "../Menhome/TryCode";
import BannerSectionHome from "../Menhome/BannerSectionHome";
import InterfaceHome from "../Menhome/InterfaceHome";
import KeyfeatureHome from "../Menhome/KeyfeatureHome";
import InfoHome from "../Menhome/InfoHome";
import OccasioniHome from "../Menhome/OccasioniHome";
import '../../../src/assets/css/home.css'
import SelectPlanHome from "../Menhome/SelectPlanHome";

function Main() {

    const targetRef = useRef(null);

    const scrollToTarget = () => {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

    return (
        <>
            <Header/>
            <BannerSectionHome scrollToTarget={scrollToTarget}/>
            <AboutSectionHome/>
            <StepsHome/>
            <TryCode/>
            <KeyfeatureHome/>
            <OccasioniHome/>
            <SelectPlanHome targetRef={targetRef}/>
            <InterfaceHome/>
            <InfoHome/>
            <div className='page_wrapper'>
                <Footer/>
            </div>
        </>
    )
}

export default Main