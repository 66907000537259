import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import Bluedots from '../../assets/images/blue_dotes.png';

const slides = [
    {
        description: 'Posizionate su ogni tavolo del tuo evento una locandina con il QR code',
        backgroundImage: 'assets/images/snapmemories/home/step-1.jpg',
    },
    {
        description: 'Lasciate che i vostri ospiti si divertano a catturare momenti durante l\'evento',
        backgroundImage: 'assets/images/snapmemories/home/step-2.jpg',
    },
    {
        description: 'Esplorate le foto e i video che sono stati condivisi',
        backgroundImage: 'assets/images/snapmemories/home/step-3.png',
    },
];

function OurValueHome() {
    return (
        <section style={{ marginTop: '80px' }} className="step_home_section home_feature white_text row_am">
            <div className="dotes_blue">
                <img src={Bluedots} alt="Blue dots" />
            </div>
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                    <h2>3 Semplici Passi</h2>
                </div>
                <div className="value_slider" data-aos="fade-in" data-aos-duration={1500}>
                    <div className="desktop-slider">
                        <Swiper
                            className="mySwiper"
                            id="value_slider"
                            slidesPerView={3}
                            modules={[Pagination]}
                            spaceBetween={15}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                1000: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1400: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                            }}
                        >
                            {slides.map((slide, index) => (
                                <SwiperSlide key={index}>
                                    <div
                                        className="value_block"
                                        style={{
                                            backgroundImage: `url(${slide.backgroundImage})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            height: '440px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            position: 'relative',
                                        }}
                                    >
                                        <div
                                            className="text-title"
                                            style={{
                                                position: 'absolute',
                                                top: '10px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                color: 'white',
                                                padding: '10px 20px',
                                                borderRadius: '5px',
                                                fontSize: '24px',
                                                fontWeight: 'bold',
                                                width: '80%'
                                            }}
                                        >
                                            # STEP {index + 1}
                                        </div>
                                        <div
                                            className="text"
                                            style={{
                                                padding: '20px',
                                                textAlign: 'center',
                                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                                borderRadius: '5px',
                                                fontSize: '16px',
                                                color: 'white',
                                            }}
                                        >
                                            {slide.description}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="mobile-view">
                        {slides.map((slide, index) => (
                            <div
                                key={index}
                                className="value_block"
                                style={{
                                    backgroundImage: `url(${slide.backgroundImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: '440px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    position: 'relative',
                                    marginBottom: '20px',
                                }}
                            >
                                <div
                                    className="text-title"
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        color: 'white',
                                        padding: '10px 20px',
                                        borderRadius: '5px',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        width: '80%'
                                    }}
                                >
                                    # STEP {index + 1}
                                </div>
                                <div
                                    className="text"
                                    style={{
                                        padding: '20px',
                                        textAlign: 'center',
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                        borderRadius: '5px',
                                        fontSize: '16px',
                                        color: 'white',
                                    }}
                                >
                                    {slide.description}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurValueHome;
