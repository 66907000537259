import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import Abtslider1 from '../../assets/images/abtslide_1.png';
import Abtslider2 from '../../assets/images/abtslide_2.png';
import Abtslider3 from '../../assets/images/abtslide_3.png';
import Abtslider4 from '../../assets/images/abtslide_4.png';
import Abtslider5 from '../../assets/images/abtslide_5.png';
import appscreen from '../../assets/images/appscreen.png'
import PhotoAlbum from "react-photo-album";
import { useTranslation } from 'react-i18next';
import photos from '../photos';
import { TypeAnimation } from 'react-type-animation';

const Slider = {
    responsive: {
        0: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    },
};

const abtSlideImages = [
    Abtslider1,
    Abtslider2,
    Abtslider3,
    Abtslider4,
    Abtslider5,
    Abtslider4,
    Abtslider4,
    Abtslider5,
    Abtslider4,
    Abtslider4,
    Abtslider5,
    Abtslider4,
];

function About() {
    const { t, i18n } = useTranslation();


    return (
        <>
            <section className="about_us_section">
                <div className="container">
                    <div className="section_title" >
                        <span className="title_badge">How it works</span>
                        <h1>{t('welcome')}</h1>

                        <h2>
                            {t('weare')} <br /> <span>{t("weare2")}</span>
                        </h2>
                        <p>
                            {t('slogan')}
                        </p>


                    </div>
                </div>
                <div className='container' style={{display:"flex",justifyContent:"center"}}>
                    <TypeAnimation id="typed"
                        sequence={['Nessun dowload', 500, 'Nessun login ', 500, 'Inquadra il codice e divertiti', 500]}
                        style={{ whiteSpace: "pre", color: "var(--primery)", fontWeight: "bold", marginBottom: "2vw" }} repeat={Infinity} />

                </div>

                <div className='container'>
                    <PhotoAlbum layout="columns" photos={photos} />
                </div>



                {/* <div className="container">
                    <div className="row abt_text" data-aos="fade-up" data-aos-duration={1500}>
                        <div className="col-md-5">
                            <h2>
                                <span>Our story</span> behind our success &amp; achievement
                            </h2>
                        </div>
                        <div className="col-md-7">
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsu
                                m has been the industrys standard dummy text ever since the when an unknown print er
                                took a galley of type and scrambled it to make a type specimen book. It has survived
                                not only five centuries, but also the leap into electronic typesetting, remaining
                                esse ntially unchanged. It was popularised in the 1960s with the release of Letraset
                                sheets containing Lorem Ipsum passages, and more recently with desktop publishing soft
                                ware like Aldus PageMaker including versions of lorem Ipsum.
                            </p>
                        </div>
                    </div>
                </div> */}
            </section>
        </>
    );
}

export default About;
