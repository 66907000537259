import React, {useState} from 'react'

function Faq() {

    const [tabMenu, tabActive] = useState({a: true});
    const [openAccordion, setOpenAccordion] = useState(null);
    const [first, setfirst] = useState(null);

    const toggleAccordion = (index) => {
        if (openAccordion === index) {
            setOpenAccordion(null);
        } else {
            setOpenAccordion(index);
        }
    };

    const togglefirst = (index) => {
        if (first === index) {
            setfirst(null);
        } else {
            setfirst(index);
        }
    };

    const accordionData = [
        {
            question: `Che cos'è SnapMemories?`,
            answer:`SnapMemories è un'app innovativa che consente di condividere e salvare foto e video durante un evento. Utilizzando un semplice QR code, puoi accedere alla web app senza bisogno di login complessi, registrazione o download di un'app. Gli utenti possono caricare contenuti, vedere quali foto sono più apprezzate e partecipare a contest organizzati dagli eventi.`,
        },
        {
            question: `Come funziona SnapMemories?`,
            answer: `Una volta acquistato uno dei nostri piani (basic, medium, premium), riceverai un QR code univoco. Gli ospiti dell'evento possono scansionare il QR code con i loro dispositivi mobili e accedere immediatamente alla web app, dove possono caricare e condividere foto e video, scaricare contenuti e partecipare a contest.`,
        },
        {
            question: 'Quali sono i vantaggi di utilizzare SnapMemories?',
            answer: `SnapMemories semplifica la condivisione di ricordi durante un evento, elimina la necessità di app complesse e registrazioni, e permette a tutti i partecipanti di interagire e condividere facilmente. Inoltre, le funzioni di contest e reazioni rendono l'esperienza più coinvolgente e divertente.`
        },
        // {
        //     question: 'Quali sono le differenze tra i piani basic, medium e premium?',
        //     answer: `I nostri piani variano in base alle funzionalità offerte. Il piano basic offre l'accesso essenziale alla condivisione di foto e video. Il piano medium include funzionalità aggiuntive come più spazio di archiviazione e strumenti avanzati di moderazione. Il piano premium offre tutte le funzionalità, tra cui un supporto dedicato, analisi avanzate e opzioni di personalizzazione complete.`,
        // },
        {
            question: 'Posso vedere le foto che gli altri pubblicano?',
            answer: `Sì, puoi vedere le foto pubblicate dagli altri partecipanti. Visitando il profilo di una persona, puoi visualizzare tutte le foto che ha pubblicato. Questo è particolarmente utile se sai che un tuo amico è bravo nella fotografia e vuoi scaricare le sue foto.`
        },
        // {
        //     question: 'Cosa devo fare se ho bisogno di assistenza?',
        //     answer: `Se hai bisogno di assistenza, puoi contattarci tramite la sezione "Supporto" del nostro sito. Il nostro team di supporto è sempre pronto ad aiutarti con qualsiasi domanda o problema tu possa avere.`
        // },
    ];

    const firstData = [
        // {
        //     question: 'Come posso acquistare un piano SnapMemories?',
        //     answer: `Per acquistare un piano SnapMemories, visita la nostra sezione "Piani e Prezzi" sul sito, seleziona il piano che meglio si adatta alle tue esigenze e procedi con il checkout. Una volta completato l'acquisto, riceverai un QR code univoco per il tuo evento.`
        // },
        {
            question: `Devo scaricare un'app per usare SnapMemories?`,
            answer: ` No, non è necessario scaricare alcuna app. SnapMemories è una web app accessibile semplicemente scansionando un QR code. Questo rende l'esperienza molto più semplice e immediata per tutti i partecipanti.`
        },
        {
            question: 'Come vengono gestiti i dati personali e i contenuti caricati?',
            answer: `La privacy e la sicurezza dei tuoi dati sono per noi una priorità. Tutti i contenuti caricati sono protetti e accessibili solo ai partecipanti dell'evento. Non condividiamo i tuoi dati personali con terze parti senza il tuo consenso.`
        },
        {
            question: `Posso personalizzare il QR code e l'interfaccia della web app?`,
            answer: `Sì, i piani medium e premium offrono opzioni di personalizzazione avanzate. Puoi personalizzare il design del QR code e l'interfaccia della web app per riflettere il tema del tuo evento.`
        },
        {
            question: `Come funziona la sezione classifica e il contest?`,
            answer: `Gli organizzatori dell'evento possono lanciare un contest tramite la sezione classifica. Durante il flusso di acquisto, l'organizzatore può registrare un video tutorial che spiega il contest. Le prime tre foto con il maggior numero di reazioni saranno visualizzate nella classifica, e l'organizzatore può decidere di premiare i partecipanti con più reazioni.`,
        },
    ]

    return (
        <>
            <section className="row_am faq_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge">Question &amp; Answer</span>
                        <h2>
                            <span>FAQs</span> - Frequently Asked Questions
                        </h2>
                    </div>
                    <div className="tab-content" id="myTabContent" data-aos="fade-up" data-aos-duration={1500}>
                        <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="genral" role="tabpanel"
                             aria-labelledby="genral-tab">
                            <div className="accordion" id="accordionExample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="accordion" id="accordionExample">
                                            {accordionData.map((item, index) => (
                                                <div className="card" key={index}>
                                                    <div className="card-header" id={`heading${index}`}>
                                                        <h2 className="mb-0">
                                                            <button
                                                                className={`btn btn-link btn-block text-left ${openAccordion === index ? '' : 'collapsed'}`}
                                                                type="button" onClick={() => toggleAccordion(index)}
                                                                aria-expanded={openAccordion === index ? 'true' : 'false'}
                                                                aria-controls={`collapse${index}`}> {item.question}
                                                                <span className="icons">
                                                                    <i className={`icofont-${openAccordion === index ? 'minus' : 'plus'}`}></i>
                                                                </span>
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id={`collapse${index}`}
                                                         className={`collapse ${openAccordion === index ? 'show' : ''}`}
                                                         aria-labelledby={`heading${index}`}
                                                         data-parent="#accordionExample">
                                                        <div className="card-body">{item.answer}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="first" id="firstExample">
                                            {firstData.map((item, index) => (
                                                <div className="card" key={index}>
                                                    <div className="card-header" id={`heading${index}`}>
                                                        <h2 className="mb-0">
                                                            <button
                                                                className={`btn btn-link btn-block text-left ${first === index ? '' : 'collapsed'}`}
                                                                type="button" onClick={() => togglefirst(index)}
                                                                aria-expanded={first === index ? 'true' : 'false'}
                                                                aria-controls={`collapse${index}`}> {item.question}
                                                                <span className="icons">
                                                                    <i className={`icofont-${first === index ? 'minus' : 'plus'}`}></i>
                                                                </span>
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id={`collapse${index}`}
                                                         className={`collapse ${first === index ? 'show' : ''}`}
                                                         aria-labelledby={`heading${index}`}
                                                         data-parent="#firstExample">
                                                        <div className="card-body">{item.answer}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.b && "show active"}`} id="pricing"
                             role="tabpanel" aria-labelledby="pricing-tab">
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.c && "show active"}`} id="account"
                             role="tabpanel" aria-labelledby="account-tab">
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.d && "show active"}`}
                             id="returns" role="tabpanel" aria-labelledby="returns-tab">
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.e && "show active"}`}
                             id="support" role="tabpanel" aria-labelledby="support-tab">
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq