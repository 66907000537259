import React, { useEffect } from 'react'
import Header from '../Header/Main'
import Footer from '../Footer/Main'
import Aos from 'aos'

import '../../../src/assets/css/home.css'
import Articals from "../Menbanner/Articals";
import Resources from "../Menbanner/Resources";

function Main() {

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

    return (
        <>
            <Header />
            <Resources/>
            <Articals/>

            <div className='page_wrapper'>
                <Footer />
            </div>
        </>
    )
}

export default Main