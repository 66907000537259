import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Bluedots from '../../assets/images/blue_dotes.png'

function SelectPlan({handleTabChange}) {

    const [tabMenu, tabActive] = useState({a: true});

    return (
        <>
            <section className="row_am pricing_section white_text">
                <div className="pricing_inner">
                    <div className="container">
                        <div className="dotes_blue">
                            <img src={Bluedots} alt="image"/>
                        </div>
                        <div className="section_title">
                            <span className="title_badge">Step numero 1</span>
                            <h2>Scegli il tuo pacchetto</h2>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="monthly" role="tabpanel"
                                 aria-labelledby="monthly-tab">
                                <div className="pricing_pannel">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="pannel_block">
                                                <div className="heading">
                                                    <h3>Basic</h3>
                                                </div>
                                                <div className="pricing">
                                                    <h3>
                                                        €89.99
                                                    </h3>
                                                </div>
                                                <ul className="features">
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                        <p>Limite di 300 foto e video</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                        <p>3 mesi di storage</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-close-circled"/>
                                                        </span>
                                                        <p>Unico tema 'Blue'</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-close-circled"/>
                                                        </span>
                                                        <p>Locandine escluse</p>
                                                    </li>
                                                </ul>
                                                <div className="btn_block">
                                                    <div
                                                        onClick={() => handleTabChange({b: true}, {piano: "basic"}, "piano")}
                                                        className="btn puprple_btn ml-0">
                                                        Seleziona il piano
                                                    </div>
                                                    <div className="btn_bottom"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="pannel_block highlited_block">
                                                <div className="heading">
                                                    <h3>Medium</h3>
                                                    <span className="offer">Raccomandato</span>
                                                </div>
                                                <div className="pricing">
                                                    <h3>
                                                        €129.99
                                                    </h3>
                                                </div>
                                                <ul className="features">
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                        <p>Limite di 600 foto e video</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                        <p>6 mesi di storage</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                        <p>Temi personalizzabili</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                        <p>15 locandine incluse</p>
                                                    </li>
                                                </ul>
                                                <div className="btn_block white_btn">
                                                    <div
                                                        onClick={() => handleTabChange({b: true}, {piano: "medium"}, "piano")}
                                                        className="btn puprple_btn ml-0">
                                                        Seleziona il piano
                                                    </div>
                                                    <div className="btn_bottom"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="pannel_block">
                                                <div className="heading">
                                                    <h3>Premium</h3>
                                                </div>
                                                <div className="pricing">
                                                    <h3>
                                                        €159.99
                                                    </h3>
                                                </div>
                                                <ul className="features">
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                        <p>Foto e video illimitati</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                        <p>1 anno di storage</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                        <p>Temi personalizzabili</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                        <p>30 locandine incluse</p>
                                                    </li>
                                                </ul>
                                                <div className="btn_block">
                                                    <div
                                                        onClick={() => handleTabChange({b: true}, {piano: "premium"}, "piano")}
                                                        className="btn puprple_btn ml-0">
                                                        Seleziona il piano
                                                    </div>
                                                    <div className="btn_bottom"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="contact_text text-center">
                                Non sei sicuro di quale scegliere? <br/>
                                <Link
                                    to="https://wa.me/+393791785539?text=Sarei%20interessato%20all'%20acquisto%20di%20un%20QRcode">Contattaci
                                </Link> per
                                maggiori informazioni!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SelectPlan;